@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active {
  color: inherit;
}
em {
  font-style: normal;
}
.s-sidebar__trigger {
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 4em;
}

.s-sidebar__trigger > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #fff;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav-link em {
}

.s-sidebar__nav {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -20em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 20em;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav ul {
  position: absolute;
  top: 4em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 20em;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4em);
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav-link {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  padding: 0 10px;
}
.s-sidebar__nav-link > * + * {
  margin:  0 0 0 10px;
}

.s-sidebar__nav-link em {
}

.s-sidebar__nav-link:hover,
.s-sidebar__nav-link:active {
  background: #4d6276;
}

.s-sidebar__nav-link > i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.s-layout__content {
  width: 100%;
}
.logo-loading {
  width: 150px;
  height: 150px;
}

@media screen and (min-width: 320px) {
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    /* width: 20em; */
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .s-sidebar__nav ul{
    width: 4em;
  }
  .s-sidebar__nav-link {
    justify-content: center;
  }
  .s-sidebar__nav-link em {
    display: none;
  }
  .s-sidebar__nav_base ul{
    width: 4em;
  }
  .s-layout__content {
    margin-left: 4em;
  }

  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .s-layout__content {
    margin-left: 20em;
  }
  .s-sidebar__trigger {
    display: none;
  }
  .s-sidebar__nav {
    width: 20em;
  }

  .s-sidebar__nav ul {
    top: 1.3em;
  }
}

/* loading */
.hexagon {
  position: absolute;
  width: 100px;
  height: 57.74px;
  background-color: #556c82;
  margin: 10px 10px;
}

.hexcontainer {
  width: 240px;
  height: 240px;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 6px solid rgba(85, 108, 130, 0.11);
}
.hexcontainer::before {
  content: '';
  width: 240px;
  height: 240px;
  display: block;
  position: absolute;
  border: 11px solid #ffcd55;
  border-radius: 50%;
  box-sizing: border-box;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
  top: -10px;
  left: -6px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-animation: 2s girarapido cubic-bezier(0.155, 1.105, 0.295, 1.12) infinite;
          animation: 2s girarapido cubic-bezier(0.155, 1.105, 0.295, 1.12) infinite;
}

.hexbox {
  position: relative;
  margin: 75px 90px;
  width: 285px;
  height: 259px;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 28.87px solid #556c82;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 28.87px solid #556c82;
}

.hexagon.hex_1 {
  /* background-color: #93328E; */
  top: 0px;
  left: 0px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.2142857143s;
          animation-delay: 0.2142857143s;
}

.hexagon.hex_2 {
  /* background-color: #00AB14; */
  top: 0px;
  left: 102px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.4285714286s;
          animation-delay: 0.4285714286s;
}

.hexagon.hex_3 {
  /* background-color: #DF1995; */
  top: 87.74px;
  left: 153px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.6428571429s;
          animation-delay: 0.6428571429s;
}

.hexagon.hex_4 {
  /* background-color: #EE2737; */
  top: 175px;
  left: 102px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.8571428571s;
          animation-delay: 0.8571428571s;
}

.hexagon.hex_5 {
  /* background-color: #F06400; */
  top: 175px;
  left: 0px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.0714285714s;
          animation-delay: 1.0714285714s;
}

.hexagon.hex_6 {
  /* background-color: #00AED6; */
  top: 87.74px;
  left: -51px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.2857142857s;
          animation-delay: 1.2857142857s;
}

.hexagon.hex_7 {
  /* background-color: #00AB14; */
  top: 87.74px;
  left: 51px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes Animasearch {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  15%, 50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  65% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes Animasearch {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  15%, 50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  65% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes girarapido {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes girarapido {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.scaled {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  display: grid;
  justify-content: center;
  align-items: center;
}

:root {
  --color-btn-primary: #00ab14;
  --color-btn-primary-hover: #00ab14d7;
  --color-danger: #ee2737;
  --color-danger-hover: #ee2738d5;
  --color-white: #fff;
}
.header-alert {
  min-height: 45px;
  padding: 10px 24px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  background: #4e4e4e;
}
.modal,
.modal-alert {
  z-index: 2500;
  transition: 0.3s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  -webkit-filter: blur(0);
          filter: blur(0);
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: gone;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.off {
  display: none;
  opacity: 0;
  visibility: visible;
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-transform: scale(0.33);
          transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(black, 0.2);
}

.modal-main,
.modal-main-alert {
  position: fixed;
  background: white;
  min-width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 4px;
}
.modal-main-alert {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.alert-main {
  position: fixed;
  background: white;
  min-width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 4px;
}

.modal-main .footer-modal,
.alert-main .footer-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-main .footer-modal > * + *,
.alert-main .footer-modal > * + * {
  margin: 0 0 0 15px;
}
.right {
  display: flex;
  justify-content: flex-end !important;
}
.padding-right-15 {
  padding-right: 15px;
}

.btn-modal {
  width: 120px;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.btn-modal-cancel {
  background: #ee2737;
  background: var(--color-danger);
  border-color: #ee2737;
  border-color: var(--color-danger);
  color: #fff;
  color: var(--color-white);
}
.btn-modal-cancel:hover,
.btn-modal-cancel:focus {
  background: #ee2738d5;
  background: var(--color-danger-hover);
  border-color: #ee2738d5;
  border-color: var(--color-danger-hover);
}
.btn-modal-submit {
  background: #00ab14;
  background: var(--color-btn-primary);
  border-color: #00ab14;
  border-color: var(--color-btn-primary);
  color: #fff;
  color: var(--color-white);
}
.btn-modal-submit:hover,
.btn-modal-submit:focus {
  background: #00ab14d7;
  background: var(--color-btn-primary-hover);
  border-color: #00ab14d7;
  border-color: var(--color-btn-primary-hover);
}
.header-modal {
  min-height: 45px;
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.modal-title {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.alert-title {
  height: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.body-modal {
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  padding: 5px;
}
.notification-modal {
  display: grid;
  min-height: 100px;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}
.icon_notification {
  width: 120px;
  height: 120px;
}
.content-notification {
  padding: 10px 5px;
  color: black;
  display: grid;
  align-items: center;
}
.react-confirm-alert-overlay {
  background: transparent !important;
  z-index: 1000 !important;
}
.field-input {
  width: 100%;
}
.field-input-block > label {
  display: block;
  color: #000000;
  font-size: 12pt;
}
@media (max-width: 480px) {
  .btn-modal {
    width: 75px;
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
  }
  .modal-main,
  .modal-main-alert {
    width: 90%;
    min-width: 90%;
  }
  .icon_notification {
    width: 100px;
    height: 100px;
  }
}
.modal_simpanTransaksi {
  display: flex;
  padding: 10px;
}
.noMemorize {
  padding-top: 5px;
  color: black;
}
.descMemorize {
  color: black;
}
.descSimpanTransaksi {
  display: flex;
  padding-top: 10px;
  padding: 10px;
}
.input {
  display: inline-block;
  width: 320px;
  margin-left: 30px;
  align-items: baseline;
}
.inputDesc {
  width: 321px;
  margin-left: 57px;
  height: 100px !important;
}
.headerMemoGen {
  display: flex;
  float: left;
}
.memorizeTitle {
  font-weight: 600;
  color: black;
  font-size: 14pt;
}

.page-import {
    padding: 1rem;
}

.welcome_title {
    margin-bottom: 0em;
    font-weight: bold;
}

.welcome_desc {
    font-size: 10pt;
    color: green;
}

.content-preference {
    text-align: justify;
    margin-top: 1rem;
}

.content-preference .react-grid-HeaderCell {
    background: #6f7a87;
    text-align: center;
    color: #fff;
    padding: 0px 8px;
}

.content-preference .react-grid-Grid {
    border-radius: 5px;
}

.content-preference .drag-handle {
    display: none;
}

.content-preference .widget-HeaderCell__value {
    font-weight: 400;
    font-size: 10.5pt;
}

.content-preference .react-grid-Cell__value {
    font-size: 10pt;
    color: #000000;

}

.content-preference .react-grid-Cell__value div {
    top: unset !important;
    -webkit-transform: unset !important;
            transform: unset !important;
}

.content-preference .react-grid-Cell {
    cursor: pointer;
    padding-right: 2px;
    padding-left: 2px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.content-preference .react-grid-Cell:nth-last-child(1) {
    cursor: pointer;
    padding-right: 2px;
    padding-left: 2px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #d9d9d9;
}

.header-info-perusahaan {
    border-bottom: 1px solid grey;
    padding: 10px 0 15px 0;
}

.form__info-perusahaan {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form__fitur-dasar {
    display: grid;
    grid-template-columns: 1fr;
}

.expand__mata-uang {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.collapses {
    max-height: -webkit-min-content;
    max-height: min-content;
    display: grid !important;
}

.collapses-table {
    /* max-height: min-content; */
    display: block;
}

.hidden {
    display: none;
}

.btn-add-currency {
    justify-content: right;
}

.child {
    margin-left: 40px;
}

.layout-2-col {
    display: grid;
    grid-template-columns: 200px 200px;
}

.form__info-perusahaan .form-item-prep {
    display: grid;
    grid-template-columns: 150px 200px;
    width: -webkit-min-content;
    width: min-content;
    padding: 5px;
}

.separated-field {
    display: grid;
    grid-template-columns: calc(50% - 5px) 50%;
    grid-gap: 5px;
    margin-top: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.content-preference .field {
    width: auto;
    margin: 5px 2px;
}

.list-item-import {
    display: grid;
    grid-template-columns: 1fr 200px;
    margin-bottom: 20px;
    grid-gap: 10px;
}

.btn-upload {
    width: 180px;
}

.import-file {
    position: relative;
    height: 32px;
}

label[aria-required]:after {
    content: '*';
    color: red;
}

input[type="file"] {
    width: 180px;
    font-size: 15px;
    color: rgb(153, 153, 153);
}

.btn-import {
    position: absolute;
    border-radius: 4px;
    background-color: #00cc00;
    font-size: 16px;
    color: #fff;
    width: 80px;
    font-size: 11pt;
    font-weight: 500;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.btn-import:hover {
    background-color: #00ad00;
}

.btn-import label {
    cursor: pointer;
}

.btn-kembali {
    background: rgb(41, 171, 226);
    color: white;
}

.btn-kembali:hover {
    background: rgb(41, 171, 226);
    color: #f5f5f5;
    border-color: unset;
}

.btn-selanjutnya {
    background: #00cc00;
    color: white;
}

.btn-selanjutnya:hover {
    background: #00cc00;
    color: #f5f5f5;
    border-color: unset;
}

.btn-selesai {
    background: #00cc00;
    color: #f5f5f5;
}

.btn-selesai:hover {
    background: #00cc00;
    color: #f5f5f5;
    border-color: unset;
}

.tags {
    background: #f5f5f5;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 5px;
    min-height: 24px;
}

input[type='checkbox'] {
    display: none;
}

.lbl-toggle {
    display: block;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    background: #f5f5f5;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
    color: gray;
}

.lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.toggle:checked+.lbl-toggle::before {
    -webkit-transform: rotate(90deg) translateX(-3px);
            transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.toggle:checked+.lbl-toggle+.collapsible-content {
    max-height: -webkit-min-content;
    max-height: min-content;
}

.toggle:checked+.lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 0;
}

.collapsible-content p {
    margin-bottom: 0;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
    .form__info-perusahaan .form-item-prep {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .form__info-perusahaan {
        grid-template-columns: 1fr;
    }

    .separated-field {
        grid-template-columns: 1fr;
    }

    .layout-2-col {
        grid-template-columns: 1fr;
    }

    .field>.ant-checkbox {
        margin-top: 5px;
    }

    .content-preference .field {
        width: 100%;
    }
}

.note {
    margin-top: 10px;
    text-align: justify;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active {
  color: inherit;
}
em {
  font-style: normal;
}
.s-sidebar__trigger_preparation {
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 4em;
}

.s-sidebar__trigger_preparation > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #fff;
}

.s-sidebar__nav_preparation:hover,
.s-sidebar__nav_preparation:focus,
.s-sidebar__trigger_preparation:focus + .s-sidebar__nav_preparation,
.s-sidebar__trigger_preparation:hover + .s-sidebar__nav_preparation {
  left: 0;
}

.s-sidebar__nav-link-preparation em {
}

.s-sidebar__nav_preparation {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -15em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 15em;
  color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav_preparation ul {
  position: absolute;
  top: 4em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 15em;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4em);
}

.s-sidebar__nav_preparation ul li {
  width: 100%;
}

.s-sidebar__nav-link-preparation {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  padding: 0 10px;
}

.s-sidebar__nav-link-preparation > * + * {
  margin: 0 0 0 10px;
}

.s-sidebar__nav-link-preparation em {
}

.s-sidebar__nav-link-preparation:hover,
.s-sidebar__nav-link-preparation:active {
  background: #4d6276;
}

.s-sidebar__nav-link-preparation > i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link-preparation > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.s-layout__content {
  width: 100%;
}

@media screen and (min-width: 320px) {
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger_preparation {
    width: 4em;
  }

  .s-sidebar__nav_preparation {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav_preparation:hover,
  .s-sidebar__nav_preparation:focus,
  .s-sidebar__trigger_preparation:hover + .s-sidebar__nav_preparation,
  .s-sidebar__trigger_preparation:focus + .s-sidebar__nav_preparation {
    width: 15em;
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .s-sidebar__nav_preparation ul{
    width: 4em;
  }
  .s-sidebar__nav-link-preparation {
    justify-content: center;
  }
  .s-sidebar__nav-link-preparation em {
    display: none;
  }
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger_preparation {
    width: 4em;
  }

  .s-sidebar__nav_preparation {
    width: 4em;
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .s-layout__content {
    margin-left: 15em;
  }
  .s-sidebar__trigger_preparation {
    display: none;
  }
  .s-sidebar__nav_preparation {
    width: 15em;
  }

  .s-sidebar__nav_preparation ul {
    top: 1.3em;
  }
}
.ant-modal {
  padding-bottom: unset;
}
.ant-modal-header {
  padding: 10px 24px ;
}
.ant-modal-header > .ant-modal-title {
  color: #fff
}
.ant-modal-body {
  padding: 5px 10px
}
.ant-layout-footer {
  height: auto;
  background-color: white;
  display: flex;
}
.ant-layout-footer button {
  margin: 5px;
}
.ant-divider-horizontal {
  margin: 5px auto;
}
.ant-select-selection-selected-value {
  width: 100%;
}
.ant-table-pagination.ant-pagination{
  margin: 2px 0;
}
.ant-table-small > .ant-table-content .ant-table-row:last-child td{
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content .ant-table-row:nth-child(even){
  background-color: #E6E4E4 ;
}
.ant-table-tbody > tr.ant-table-row-selected td,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: unset
}
.ant-input::-webkit-input-placeholder, .ant-input-number-input::-webkit-input-placeholder {
  color: #7d7d7d;
}
.ant-input:-ms-input-placeholder, .ant-input-number-input:-ms-input-placeholder {
  color: #7d7d7d;
}
.ant-checkbox-disabled + span, 
.ant-input::placeholder,
.ant-select-selection__placeholder,
.ant-input-number-input::placeholder {
  color: #7d7d7d;
}
.ant-table-placeholder {
  padding: 0;
}
.ant-select-dropdown-menu-item {
  padding: 0px 12px;
}
.ant-input[disabled] {
  border-color: #8C8B8B;
}
/* .ant-select-selection__rendered,
.ant-select-sm .ant-select-selection__rendered{
  margin-left: 0;
} */

@font-face {
  font-family: 'Segoe UI';
  src: url(/static/media/segoeui.69917140.ttf)
}
* {
  font-family: 'Segoe UI', sans-serif;
 }
#root {
  height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'Open Sans',
    sans-serif; */
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

