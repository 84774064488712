:root {
  --color-btn-primary: #00ab14;
  --color-btn-primary-hover: #00ab14d7;
  --color-danger: #ee2737;
  --color-danger-hover: #ee2738d5;
  --color-white: #fff;
}
.header-alert {
  min-height: 45px;
  padding: 10px 24px;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  background: #4e4e4e;
}
.modal,
.modal-alert {
  z-index: 2500;
  transition: 0.3s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: gone;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}
.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.off {
  display: none;
  opacity: 0;
  visibility: visible;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(black, 0.2);
}

.modal-main,
.modal-main-alert {
  position: fixed;
  background: white;
  min-width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
.modal-main-alert {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.alert-main {
  position: fixed;
  background: white;
  min-width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

.modal-main .footer-modal,
.alert-main .footer-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-main .footer-modal > * + *,
.alert-main .footer-modal > * + * {
  margin: 0 0 0 15px;
}
.right {
  display: flex;
  justify-content: flex-end !important;
}
.padding-right-15 {
  padding-right: 15px;
}

.btn-modal {
  width: 120px;
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.btn-modal-cancel {
  background: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
}
.btn-modal-cancel:hover,
.btn-modal-cancel:focus {
  background: var(--color-danger-hover);
  border-color: var(--color-danger-hover);
}
.btn-modal-submit {
  background: var(--color-btn-primary);
  border-color: var(--color-btn-primary);
  color: var(--color-white);
}
.btn-modal-submit:hover,
.btn-modal-submit:focus {
  background: var(--color-btn-primary-hover);
  border-color: var(--color-btn-primary-hover);
}
.header-modal {
  min-height: 45px;
  padding: 10px 24px;
  color: rgba(0, 0, 0, 0.65);
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.modal-title {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.alert-title {
  height: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.body-modal {
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  padding: 5px;
}
.notification-modal {
  display: grid;
  min-height: 100px;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}
.icon_notification {
  width: 120px;
  height: 120px;
}
.content-notification {
  padding: 10px 5px;
  color: black;
  display: grid;
  align-items: center;
}
.react-confirm-alert-overlay {
  background: transparent !important;
  z-index: 1000 !important;
}
.field-input {
  width: 100%;
}
.field-input-block > label {
  display: block;
  color: #000000;
  font-size: 12pt;
}
@media (max-width: 480px) {
  .btn-modal {
    width: 75px;
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
  }
  .modal-main,
  .modal-main-alert {
    width: 90%;
    min-width: 90%;
  }
  .icon_notification {
    width: 100px;
    height: 100px;
  }
}
.modal_simpanTransaksi {
  display: flex;
  padding: 10px;
}
.noMemorize {
  padding-top: 5px;
  color: black;
}
.descMemorize {
  color: black;
}
.descSimpanTransaksi {
  display: flex;
  padding-top: 10px;
  padding: 10px;
}
.input {
  display: inline-block;
  width: 320px;
  margin-left: 30px;
  align-items: baseline;
}
.inputDesc {
  width: 321px;
  margin-left: 57px;
  height: 100px !important;
}
.headerMemoGen {
  display: flex;
  float: left;
}
.memorizeTitle {
  font-weight: 600;
  color: black;
  font-size: 14pt;
}
