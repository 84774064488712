*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active {
  color: inherit;
}
em {
  font-style: normal;
}
.s-sidebar__trigger {
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 4em;
}

.s-sidebar__trigger > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #fff;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav-link em {
}

.s-sidebar__nav {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -20em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 20em;
  height: 100%;
  color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav ul {
  position: absolute;
  top: 4em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 20em;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4em);
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav-link {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  padding: 0 10px;
}
.s-sidebar__nav-link > * + * {
  margin:  0 0 0 10px;
}

.s-sidebar__nav-link em {
}

.s-sidebar__nav-link:hover,
.s-sidebar__nav-link:active {
  background: #4d6276;
}

.s-sidebar__nav-link > i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.s-layout__content {
  width: 100%;
}
.logo-loading {
  width: 150px;
  height: 150px;
}

@media screen and (min-width: 320px) {
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    /* width: 20em; */
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .s-sidebar__nav ul{
    width: 4em;
  }
  .s-sidebar__nav-link {
    justify-content: center;
  }
  .s-sidebar__nav-link em {
    display: none;
  }
  .s-sidebar__nav_base ul{
    width: 4em;
  }
  .s-layout__content {
    margin-left: 4em;
  }

  .s-sidebar__trigger {
    width: 4em;
  }

  .s-sidebar__nav {
    width: 4em;
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .s-layout__content {
    margin-left: 20em;
  }
  .s-sidebar__trigger {
    display: none;
  }
  .s-sidebar__nav {
    width: 20em;
  }

  .s-sidebar__nav ul {
    top: 1.3em;
  }
}

/* loading */
.hexagon {
  position: absolute;
  width: 100px;
  height: 57.74px;
  background-color: #556c82;
  margin: 10px 10px;
}

.hexcontainer {
  width: 240px;
  height: 240px;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 6px solid rgba(85, 108, 130, 0.11);
}
.hexcontainer::before {
  content: '';
  width: 240px;
  height: 240px;
  display: block;
  position: absolute;
  border: 11px solid #ffcd55;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
  top: -10px;
  left: -6px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-animation: 2s girarapido cubic-bezier(0.155, 1.105, 0.295, 1.12) infinite;
          animation: 2s girarapido cubic-bezier(0.155, 1.105, 0.295, 1.12) infinite;
}

.hexbox {
  position: relative;
  margin: 75px 90px;
  width: 285px;
  height: 259px;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 28.87px solid #556c82;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 28.87px solid #556c82;
}

.hexagon.hex_1 {
  /* background-color: #93328E; */
  top: 0px;
  left: 0px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.2142857143s;
          animation-delay: 0.2142857143s;
}

.hexagon.hex_2 {
  /* background-color: #00AB14; */
  top: 0px;
  left: 102px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.4285714286s;
          animation-delay: 0.4285714286s;
}

.hexagon.hex_3 {
  /* background-color: #DF1995; */
  top: 87.74px;
  left: 153px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.6428571429s;
          animation-delay: 0.6428571429s;
}

.hexagon.hex_4 {
  /* background-color: #EE2737; */
  top: 175px;
  left: 102px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 0.8571428571s;
          animation-delay: 0.8571428571s;
}

.hexagon.hex_5 {
  /* background-color: #F06400; */
  top: 175px;
  left: 0px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.0714285714s;
          animation-delay: 1.0714285714s;
}

.hexagon.hex_6 {
  /* background-color: #00AED6; */
  top: 87.74px;
  left: -51px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.2857142857s;
          animation-delay: 1.2857142857s;
}

.hexagon.hex_7 {
  /* background-color: #00AB14; */
  top: 87.74px;
  left: 51px;
  -webkit-animation: Animasearch 3s ease-in-out infinite;
          animation: Animasearch 3s ease-in-out infinite;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes Animasearch {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  15%, 50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  65% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes Animasearch {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  15%, 50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  65% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes girarapido {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes girarapido {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.scaled {
  transform: scale(0.6);
  display: grid;
  justify-content: center;
  align-items: center;
}
