.ant-modal {
  padding-bottom: unset;
}
.ant-modal-header {
  padding: 10px 24px ;
}
.ant-modal-header > .ant-modal-title {
  color: #fff
}
.ant-modal-body {
  padding: 5px 10px
}
.ant-layout-footer {
  height: auto;
  background-color: white;
  display: flex;
}
.ant-layout-footer button {
  margin: 5px;
}
.ant-divider-horizontal {
  margin: 5px auto;
}
.ant-select-selection-selected-value {
  width: 100%;
}
.ant-table-pagination.ant-pagination{
  margin: 2px 0;
}
.ant-table-small > .ant-table-content .ant-table-row:last-child td{
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content .ant-table-row:nth-child(even){
  background-color: #E6E4E4 ;
}
.ant-table-tbody > tr.ant-table-row-selected td,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: unset
}
.ant-checkbox-disabled + span, 
.ant-input::placeholder,
.ant-select-selection__placeholder,
.ant-input-number-input::placeholder {
  color: #7d7d7d;
}
.ant-table-placeholder {
  padding: 0;
}
.ant-select-dropdown-menu-item {
  padding: 0px 12px;
}
.ant-input[disabled] {
  border-color: #8C8B8B;
}
/* .ant-select-selection__rendered,
.ant-select-sm .ant-select-selection__rendered{
  margin-left: 0;
} */
