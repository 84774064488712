.page-import {
    padding: 1rem;
}

.welcome_title {
    margin-bottom: 0em;
    font-weight: bold;
}

.welcome_desc {
    font-size: 10pt;
    color: green;
}

.content-preference {
    text-align: justify;
    margin-top: 1rem;
}

.content-preference .react-grid-HeaderCell {
    background: #6f7a87;
    text-align: center;
    color: #fff;
    padding: 0px 8px;
}

.content-preference .react-grid-Grid {
    border-radius: 5px;
}

.content-preference .drag-handle {
    display: none;
}

.content-preference .widget-HeaderCell__value {
    font-weight: 400;
    font-size: 10.5pt;
}

.content-preference .react-grid-Cell__value {
    font-size: 10pt;
    color: #000000;

}

.content-preference .react-grid-Cell__value div {
    top: unset !important;
    transform: unset !important;
}

.content-preference .react-grid-Cell {
    cursor: pointer;
    padding-right: 2px;
    padding-left: 2px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.content-preference .react-grid-Cell:nth-last-child(1) {
    cursor: pointer;
    padding-right: 2px;
    padding-left: 2px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #d9d9d9;
}

.header-info-perusahaan {
    border-bottom: 1px solid grey;
    padding: 10px 0 15px 0;
}

.form__info-perusahaan {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.form__fitur-dasar {
    display: grid;
    grid-template-columns: 1fr;
}

.expand__mata-uang {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.collapses {
    max-height: min-content;
    display: grid !important;
}

.collapses-table {
    /* max-height: min-content; */
    display: block;
}

.hidden {
    display: none;
}

.btn-add-currency {
    justify-content: right;
}

.child {
    margin-left: 40px;
}

.layout-2-col {
    display: grid;
    grid-template-columns: 200px 200px;
}

.form__info-perusahaan .form-item-prep {
    display: grid;
    grid-template-columns: 150px 200px;
    width: min-content;
    padding: 5px;
}

.separated-field {
    display: grid;
    grid-template-columns: calc(50% - 5px) 50%;
    grid-gap: 5px;
    margin-top: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-5 {
    margin-bottom: 5px;
}

.content-preference .field {
    width: auto;
    margin: 5px 2px;
}

.list-item-import {
    display: grid;
    grid-template-columns: 1fr 200px;
    margin-bottom: 20px;
    grid-gap: 10px;
}

.btn-upload {
    width: 180px;
}

.import-file {
    position: relative;
    height: 32px;
}

label[aria-required]:after {
    content: '*';
    color: red;
}

input[type="file"] {
    width: 180px;
    font-size: 15px;
    color: rgb(153, 153, 153);
}

.btn-import {
    position: absolute;
    border-radius: 4px;
    background-color: #00cc00;
    font-size: 16px;
    color: #fff;
    width: 80px;
    font-size: 11pt;
    font-weight: 500;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.btn-import:hover {
    background-color: #00ad00;
}

.btn-import label {
    cursor: pointer;
}

.btn-kembali {
    background: rgb(41, 171, 226);
    color: white;
}

.btn-kembali:hover {
    background: rgb(41, 171, 226);
    color: #f5f5f5;
    border-color: unset;
}

.btn-selanjutnya {
    background: #00cc00;
    color: white;
}

.btn-selanjutnya:hover {
    background: #00cc00;
    color: #f5f5f5;
    border-color: unset;
}

.btn-selesai {
    background: #00cc00;
    color: #f5f5f5;
}

.btn-selesai:hover {
    background: #00cc00;
    color: #f5f5f5;
    border-color: unset;
}

.tags {
    background: #f5f5f5;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 5px;
    min-height: 24px;
}

input[type='checkbox'] {
    display: none;
}

.lbl-toggle {
    display: block;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    background: #f5f5f5;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
    color: gray;
}

.lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
}

.toggle:checked+.lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
}

.toggle:checked+.lbl-toggle+.collapsible-content {
    max-height: min-content;
}

.toggle:checked+.lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 0;
}

.collapsible-content p {
    margin-bottom: 0;
}

@media screen and (min-width: 360px) and (max-width: 768px) {
    .form__info-perusahaan .form-item-prep {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .form__info-perusahaan {
        grid-template-columns: 1fr;
    }

    .separated-field {
        grid-template-columns: 1fr;
    }

    .layout-2-col {
        grid-template-columns: 1fr;
    }

    .field>.ant-checkbox {
        margin-top: 5px;
    }

    .content-preference .field {
        width: 100%;
    }
}

.note {
    margin-top: 10px;
    text-align: justify;
}