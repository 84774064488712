*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover,
a:active {
  color: inherit;
}
em {
  font-style: normal;
}
.s-sidebar__trigger_preparation {
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 4em;
}

.s-sidebar__trigger_preparation > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #fff;
}

.s-sidebar__nav_preparation:hover,
.s-sidebar__nav_preparation:focus,
.s-sidebar__trigger_preparation:focus + .s-sidebar__nav_preparation,
.s-sidebar__trigger_preparation:hover + .s-sidebar__nav_preparation {
  left: 0;
}

.s-sidebar__nav-link-preparation em {
}

.s-sidebar__nav_preparation {
  z-index: 1;
  position: absolute;
  top: 0;
  left: -15em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 15em;
  color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav_preparation ul {
  position: absolute;
  top: 4em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 15em;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4em);
}

.s-sidebar__nav_preparation ul li {
  width: 100%;
}

.s-sidebar__nav-link-preparation {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  padding: 0 10px;
}

.s-sidebar__nav-link-preparation > * + * {
  margin: 0 0 0 10px;
}

.s-sidebar__nav-link-preparation em {
}

.s-sidebar__nav-link-preparation:hover,
.s-sidebar__nav-link-preparation:active {
  background: #4d6276;
}

.s-sidebar__nav-link-preparation > i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link-preparation > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.s-layout__content {
  width: 100%;
}

@media screen and (min-width: 320px) {
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger_preparation {
    width: 4em;
  }

  .s-sidebar__nav_preparation {
    width: 4em;
    left: 0;
  }

  .s-sidebar__nav_preparation:hover,
  .s-sidebar__nav_preparation:focus,
  .s-sidebar__trigger_preparation:hover + .s-sidebar__nav_preparation,
  .s-sidebar__trigger_preparation:focus + .s-sidebar__nav_preparation {
    width: 15em;
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .s-sidebar__nav_preparation ul{
    width: 4em;
  }
  .s-sidebar__nav-link-preparation {
    justify-content: center;
  }
  .s-sidebar__nav-link-preparation em {
    display: none;
  }
  .s-layout__content {
    margin-left: 4em;
  }
  /* Sidebar */
  .s-sidebar__trigger_preparation {
    width: 4em;
  }

  .s-sidebar__nav_preparation {
    width: 4em;
    left: 0;
  }
}

@media screen and (min-width: 769px) {
  .s-layout__content {
    margin-left: 15em;
  }
  .s-sidebar__trigger_preparation {
    display: none;
  }
  .s-sidebar__nav_preparation {
    width: 15em;
  }

  .s-sidebar__nav_preparation ul {
    top: 1.3em;
  }
}