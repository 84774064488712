@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@font-face {
  font-family: 'Segoe UI';
  src: url('./assets/fonts/segoeui.ttf')
}
* {
  font-family: 'Segoe UI', sans-serif;
 }
#root {
  height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'Open Sans',
    sans-serif; */
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
